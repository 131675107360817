<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="7">
                        <p class="display-1 font-weight-thin mb-2">Accounts</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="pl-2 year-container">
                        <RoadtripYearDropdown :year="selectedYear" @changed="onYearChanged($event)" />
                    </v-col>
                    <v-col cols="2" class="pl-2 quarter-container">
                        <RoadtripQuarterDropdown :quarter="selectedQuarter" @changed="onQuarterChanged($event)" />
                    </v-col>
                </v-row> 
                <StatisticInfoCards 
                    :title1="`Totaal:`"
                    :value1="totalRegistered"
                    :title2="`Actief:`"
                    :value2="totalActive"
                    :title3="`Nieuw:`"
                    :value3="totalNew"
                    />
                <v-row>
                    <v-col>
                        <div class="pb-3">{{accountDescription}}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-5">
                    <v-col>
                        <p class="title mt-0 mb-0">Geregistreerd</p>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-0 px-0 sort-container">
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'voornaam'">
                            Voornaam
                            <v-icon large right dark class="pr-2">{{currentSort == 'voornaam' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'meegereden'">
                            Meegereden
                            <v-icon large right dark class="pr-2">{{currentSort == 'meegereden' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'actief'">
                            Laatst actief
                            <v-icon large right dark class="pr-2">{{currentSort == 'actief' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(account, index) in sortedArray" :key="index">
                        <v-card class="participant-card primary" @click="goToAccountId(account.accountId)">
                            <v-card-title
                            class="subtitle-1 cols-12 pt-2 pb-0">
                                {{ getCardText(account) }}
                            </v-card-title>
                            <v-list class="pa-0 primary">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-thin">{{ getCardSubText(account) }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import StatisticInfoCards from "@/components/admin/StatisticInfoCards";
import RoadtripYearDropdown from '@/components/common/options/RoadtripYearDropdown';
import RoadtripQuarterDropdown from '@/components/common/options/RoadtripQuarterDropdown';
export default {
    name: 'Accounts',
    components: 
    {
        LoadingIcon,
        StatisticInfoCards,
        RoadtripYearDropdown,
        RoadtripQuarterDropdown
    },
    mixins: [RouteMixin, FormatMixin],
    data: () => ({
        isLoading: false,
        selectedYear: "",
        selectedQuarter: 1,
        accountDescription: "Hierboven zijn de belangrijkste statistieken van de huidige periode weergegeven. Het is mogelijk om alle gegevens op deze pagina per kwartaal en per jaar te sorteren. Daarnaast zijn alle accounts hieronder alfabetisch gesorteerd.",
        currentSort: 'voornaam',
        totalRegistered: '',
        totalActive: '',
        totalNew: '',
        accounts: []
    }),
    computed: {
        sortedArray() 
        {
            let sortedAccounts = this.accounts;
		
            sortedAccounts = sortedAccounts.sort((a,b) => {
                let text1 = "",
                    text2 = "";

                switch(this.currentSort)
                {
                    case "voornaam":
                        text1 = a.firstName.toLowerCase();
                        text2 = b.firstName.toLowerCase();
                        break;
                    case "meegereden":
                        text1 = a.participatedTrips;
                        text2 = b.participatedTrips;
                        break;
                    case "actief":
                        text1 = a.lastActive;
                        text2 = b.lastActive;
                        break;
                }

                if (text1 < text2) {
                    return -1
                }
                if (text1 > text2) {
                    return 1
                }
                return 0
            })
            return sortedAccounts;
        }
    },
    mounted()
    {
        this.selectedYear = `${this.getCurrentYear()}`;
        this.selectedQuarter = this.getCurrentQuarter();
        this.isMounted = true;
    },
    methods: 
    {
        onYearChanged(year)
        {
            this.selectedYear = year;
            this.getAccounts();
        },
        onQuarterChanged(quarter)
        {
            this.selectedQuarter = quarter;
            this.getAccounts();
        },
        getAccounts()
        {
            if(this.isMounted == false || this.isMounted == undefined) return;

            this.isLoading = true;
            let request = {
                year: this.selectedYear,
                quarter: this.selectedQuarter
            }

            this.getAccountStatisticsByTimeFrame(request);
            this.getAccountsByTimeFrame(request);
        },
        getAccountStatisticsByTimeFrame(request)
        {
            this.isLoading = true;
            this.$store.dispatch('accountModule/getAccountStatisticsByTimeFrame', request)
            .then(data => {
                this.totalRegistered = `${data.totalRegistered}`;
                this.totalActive = `${data.totalActive}`;
                this.totalNew = `${data.totalNew}`;
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        getAccountsByTimeFrame(request)
        {
            this.isLoading = true;
            this.$store.dispatch('accountModule/getAccountsByTimeFrame', request)
            .then(data => {
                this.accounts = data;
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        getCardText(item)
        {
           return `${item.firstName} ${item.lastName} | ${item.lastActive} dagen geleden actief`;
        },
        getCardSubText(item)
        {
           return `Aantal roadtrips meegereden: ${item.participatedTrips} (${item.participatedDistance} km)`;
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}

.theme--dark.v-card.participant-card,
.participant-card .theme--dark.v-list
{
    background-color: #005E39;
}

.participant-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.participant-card 
{
    border-radius: 10px;
}
</style>